import { React, useRef, useState, useEffect } from "react";

import Footer2 from "./Footer2";
import HeaderLower from "./HeaderLower";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import secureLocalStorage from "react-secure-storage";

import RegisterService from "../services/register.service";

import { Navigate, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import Swal from "sweetalert2";

import UserformService from "../services/userform.service";

import Select from "react-select";
import ReCAPTCHA from "react-google-recaptcha";
import StorageService from "../services/storage.service";

function RegisterForm(props) {
  const TITLE = process.env.REACT_APP_API_SITE_TITLE + " | Registration Form";
  const [fields, setFields] = useState({
    name: "",
    lname: "",
    emailid: "",
    address: "",
    p_address: "",
    city: "",
    state: "",
    zipcode: "",
    sales: "",
    phone: "",
    showroom: "",
    captcha: "",
  });

  const [errors, setErrors] = useState({});

  const [states, setStates] = useState([]);
  const [captcha, setCaptcha] = useState(false);
  const [showroom, setShowroom] = useState([]);

  const [signoutTime, setSignoutTime] = useState(900000);
  const [value_userform, setValue_userform] = useState([]);

  const navigate = useNavigate();

  // secureLocalStorage.removeItem("registerform");
  localStorage.setItem("returnpage", true);

  useEffect(() => {
    async function getStates() {
      let errors = {};

      try {
        const response = await UserformService.getStates();

        var states = response.data.data;
        const results = [];
        states.map((value) => {
          return results.push({
            value: value.code,
            label: value.state_name,
          });
        });
        await setStates([...results]);

        errors["voucher"] = response.data.response;
      } catch (err) {
        if (err.status === 429 || err.status === "429") {
          alert("Max Limit Reached. Please wait.");
        }
      }
    }
    getStates();

    async function getLocations() {
      let errors = {};

      try {
        const response = await RegisterService.getStoreLocation();

        var locations = response.data.data;
        const results = [];
        locations.map((value) => {
          return results.push({
            value: value.store_location,
            label: value.store_location,
          });
        });
        await setShowroom([...results]);

        errors["voucher"] = response.data.response;
      } catch (err) {
        if (err.status === 429 || err.status === "429") {
          alert("Max Limit Reached. Please wait.");
        }
      }
    }
    getLocations();
  }, []);

  timeout();
  function timeout() {
    setTimeout(function () {
      logout();
    }, signoutTime);
  }

  const logout = () => {
    destroy();
  };

  const destroy = () => {
    localStorage.setItem("returnpage", false);
    // localStorage.removeItem("return_page");
    // localStorage.removeItem("registerForm");
    // localStorage.removeItem("isRegister");
    localStorage.removeItem("mattress-questionnare");
    window.location.replace("/");
  };

  const ref = useRef();

  const pasted = (e) => {
    e.preventDefault();
    return false;
  };

  const onlyNumbers = (evt) => {
    const { name, value } = evt.target;
    evt.target.value = evt.target.value.replace(/[^0-9]+/g, "");

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: evt.target.value,
      };
    });
  };

  const handleChange = (e) => {
    let { value, name } = e.target;
    if (name === "state" && value === "true") {
      value = "";
    }
    if (name === "showroom" && value === "true") {
      value = "";
    }

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };
  const saveErrors = (err) => {
    setErrors(err);
  };

  const submituserRegistrationForm = (e) => {
    e.preventDefault();
    let data = {
      fname: fields?.name,
      lname: fields?.lname,
      email: fields?.emailid,
      address: fields?.address,
      p_address: fields?.p_address,
      city: fields?.city,
      state: fields?.state,
      zip: fields?.zipcode,
      sales: fields?.sales,
      phone: fields?.phone,
      showroom: fields?.showroom,
      captcha: captcha,
      ans1: StorageService.getAns("ans1"),
      ans2: StorageService.getAns("ans2"),
      ans3: StorageService.getAns("ans3"),
      ans4: StorageService.getAns("ans4"),
      ans5: StorageService.getAns("ans5"),
      ans6: StorageService.getAns("ans6"),
      ans7: StorageService.getAns("ans7"),
      ans8: StorageService.getAns("ans8"),
      ans9: StorageService.getAns("ans9"),
      ans10: StorageService.getAns("ans10"),
      ans11: StorageService.getAns("ans11"),
      ans12: StorageService.getAns("ans12"),
    };

    try {
      const responseuserform = RegisterService.regData(data);
      responseuserform
        .then((response) => {
          var valueUserform = response?.data?.status;
          setValue_userform(valueUserform);

          Swal.fire({
            title: "Successful!",
            text: "Form Submitted!",
            type: "success",
            icon: "success",
          }).then((result) => {
            secureLocalStorage.setItem(
              "registerform",
              JSON.stringify({
                name: fields.name,
                lname: fields.lname,
                emailid: fields.emailid,
                address: fields.address,
                p_address: fields.p_address,
                city: fields.city,
                state: fields.state,
                zipcode: fields.zipcode,
                sales: fields.sales,
                phone: fields.phone,
                showroom: fields.showroom,
                captcha: captcha,
              })
            );
            // localStorage.setItem("isQuestionnare", "false");
            // localStorage.setItem("isRegister", true);
            localStorage.setItem("isVoucher", true);
            localStorage.setItem("returnpage", false);
            navigate("/register/registration-thankyou");
          });

          if (value_userform?.includes("redirect")) {
          }
        })
        .catch((err) => {
          console.log(err);

          let errorData = {};
          errorData["name"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.fname &&
            err?.response?.data?.message.fname[0];
          errorData["lname"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.lname &&
            err?.response?.data?.message.lname[0];
          errorData["emailid"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.email &&
            err?.response?.data?.message.email[0];
          errorData["address"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.address &&
            err?.response?.data?.message.address[0];
          errorData["p_address"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.p_address &&
            err?.response?.data?.message.p_address[0];
          errorData["city"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.city &&
            err?.response?.data?.message.city[0];
          errorData["state"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.state &&
            err?.response?.data?.message.state[0];
          errorData["zipcode"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.zip &&
            err?.response?.data?.message.zip[0];
          errorData["sales"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.sales &&
            err?.response?.data?.message.sales[0];
          errorData["phone"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.phone &&
            err?.response?.data?.message.phone[0];
          errorData["showroom"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.showroom &&
            err?.response?.data?.message.showroom[0];
          errorData["checkederrorcaptcha"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.captcha &&
            err?.response?.data?.message.captcha[0];
          errorData["checkederrortwo"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.size &&
            err?.response?.data?.message.size[0];
          saveErrors(errorData);
          setTimeout(() => {
            ref?.current?.focus();
          }, 200);
        });
    } catch (err) {
      alert(err);
      if (err.response.status === 429 || err.response.status === "429") {
        alert("Max Limit Reached. Please wait.");
      }
      let errorData = {};
      errorData["name"] = err?.response?.data?.message.fname[0];
      errorData["lname"] = err?.response?.data?.message.lname[0];
      errorData["emailid"] = err?.response?.data?.message.phone[0];
      errorData["address"] = err?.response?.data?.message.address[0];
      errorData["p_address"] = err?.response?.data?.message.p_address[0];
      errorData["city"] = err?.response?.data?.message.city[0];
      errorData["state"] = err?.response?.data?.message.state[0];
      errorData["sales"] = err?.response?.data?.message.sales[0];
      errorData["zipcode"] = err?.response?.data?.message.zip[0];
      errorData["phone"] = err?.response?.data?.message.phone[0];
      errorData["showroom"] = err?.response?.data?.message.showroom[0];
      errorData["checkederrorcaptcha"] =
        err?.response?.data?.message.captcha[0];
      saveErrors(errorData);
    }
  };
  // const validate = (e) => {
  //   let errors = {};
  //   let isformIsValid = true;
  //   if (!captcha) {
  //     isformIsValid = false;
  //     errors["checkederrorcaptcha"] = "Captcha not verified";
  //     setErrors(errors);
  //     return false;
  //   }
  //   return isformIsValid;
  // };
  function onCaptchaChange(value) {
    if (value === null) {
      setCaptcha(false);
    } else {
      setCaptcha(true);
    }
  }
  const isRegister = localStorage.getItem("isRegister");
  const isQuestionnare = localStorage.getItem("isQuestionnare");
  if (
    isRegister != "true" ||
    isRegister == "undefined" ||
    isRegister == null ||
    isQuestionnare != "true"
  ) {
    localStorage.setItem("isQuestionnare", "false");
    // localStorage.setItem('isRegister', 'false');
    return <Navigate to="/register" />;
  }
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="container-fluid">
        {/* <HeaderInner/> */}
        <HeaderLower />
        <div id="main-registration-container">
          <div className="white-box form" id="font-style">
            <h1>Register your guest for their Reward Card!</h1>
            <hr />
            <form
              name="userRegistrationForm"
              className="form-outer"
              onSubmit={submituserRegistrationForm}
              // onClick={this.popup}
            >
              <div className="row">
                <div className="col-md-6">
                  <label className="formsub">
                    Customer First Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Customer First Name"
                    style={errors.name ? { border: "1px solid red" } : null}
                    name="name"
                    id="fname"
                    className="searchBox_deals vc-validate"
                    ref={errors.name ? ref : null}
                    value={fields.name || ""}
                    autoFocus=""
                    onChange={handleChange}
                  />
                  <div className="errorMsg">{errors.name}</div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Customer Last Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="lname"
                    style={
                      errors.lname && !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    placeholder="Customer Last Name"
                    id="lname"
                    className="searchBox_deals vc-validate"
                    autoFocus=""
                    value={fields.lname || ""}
                    ref={errors.lname && !errors.name ? ref : null}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.lname && !errors.name ? errors.lname : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Customer Email <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="emailid"
                    ref={
                      errors.emailid && !errors.lname && !errors.name
                        ? ref
                        : null
                    }
                    style={
                      errors.emailid && !errors.lname && !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    id="emailid"
                    placeholder="Customer Email"
                    autoFocus=""
                    value={fields.emailid || ""}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.emailid && !errors.lname && !errors.name
                      ? errors.emailid
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Customer Phone # <span className="red_point">*</span>
                    <span className="red_point"></span>
                  </label>
                  <input
                    type="text"
                    id="phone"
                    style={
                      errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    name="phone"
                    placeholder="Customer Phone"
                    value={fields.phone || ""}
                    ref={
                      errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    onChange={handleChange}
                    onKeyUp={onlyNumbers}
                    onPaste={pasted}
                  />
                  <div className="errorMsg">
                    {" "}
                    {errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.phone
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label required className="formsub">
                    Customer Address (Optional)
                    <span className="red_point"></span>
                  </label>
                  <input
                    type="text"
                    name="address"
                    placeholder="Customer Address"
                    id="address"
                    className="searchBox_deals vc-validate"
                    // ref={
                    //   errors.address &&
                    //   !errors.emailid &&
                    //   !errors.lname &&
                    //   !errors.name
                    //     ? ref
                    //     : null
                    // }
                    // autoFocus=""
                    value={fields.address || ""}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.address &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.address
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Apartment/Suite # (Optional)
                    <span className="red_point"></span>
                  </label>
                  <input
                    type="text"
                    name="p_address"
                    id="p_address"
                    placeholder="Apartment/Suite #"
                    // autoFocus=""
                    value={fields.p_address || ""}
                    // ref={
                    //   errors.p_address &&
                    //   !errors.address &&
                    //   !errors.emailid &&
                    //   !errors.lname &&
                    //   !errors.name
                    //     ? ref
                    //     : null
                    // }
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.p_address &&
                    !errors.address &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.p_address
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    City (Optional)<span className="red_point"></span>
                  </label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    placeholder="City"
                    value={fields.city || ""}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.city &&
                    !errors.p_address &&
                    !errors.address &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.city
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    State (Optional)<span className="red_point"></span>
                  </label>
                  <Select
                    classNamePrefix="react-select"
                    // className={`select_1 dropdown ${
                    //   errors.state &&
                    //   !errors.city &&
                    //   !errors.address &&
                    //   !errors.emailid &&
                    //   !errors.lname &&
                    //   !errors.name &&
                    //   !errors.store_location &&
                    //   !errors.sales_name
                    //     ? "error-select-search"
                    //     : ""
                    // }`}
                    placeholder="Select State"
                    options={states}
                    isSearchable={true}
                    isClearable={true}
                    name="state"
                    // ref={
                    //   errors.state &&
                    //   !errors.city &&
                    //   !errors.address &&
                    //   !errors.emailid &&
                    //   !errors.lname &&
                    //   !errors.name &&
                    //   !errors.store_location &&
                    //   !errors.sales_name
                    //     ? ref
                    //     : null
                    // }
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setFields((prevValue) => {
                          return {
                            ...prevValue,
                            ["state"]: selectedOption.value,
                          };
                        });
                      } else {
                        setFields((prevValue) => {
                          return {
                            ...prevValue,
                            ["state"]: "",
                          };
                        });
                      }
                    }}
                  />
                  <div className="errorMsg">
                    {errors.state &&
                    !errors.city &&
                    !errors.address &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name &&
                    !errors.store_location &&
                    !errors.sales_name
                      ? errors.state
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Zip Code (Optional)<span className="red_point"></span>
                  </label>
                  <input
                    type="text"
                    // onkeypress="return isNumberKey(event)"
                    id="zipcode"
                    name="zipcode"
                    placeholder="Zip Code"
                    value={fields.zipcode || ""}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.zipcode &&
                    !errors.state &&
                    !errors.City &&
                    !errors.p_address &&
                    !errors.address &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.zipcode
                      : null}
                  </div>
                  {/* <span style="color:red" id="zip_msg"></span> */}
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Showroom Location <span className="red_point">*</span>
                  </label>

                  <Select
                    classNamePrefix="react-select"
                    className={`select_1 dropdown ${
                      errors.showroom &&
                      !errors.zipcode &&
                      !errors.state &&
                      !errors.City &&
                      !errors.p_address &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? "error-select-search"
                        : ""
                    }`}
                    placeholder="Select Showroom Location"
                    options={showroom}
                    isSearchable={true}
                    isClearable={true}
                    name="showroom"
                    ref={
                      errors.showroom &&
                      !errors.zipcode &&
                      !errors.state &&
                      !errors.City &&
                      !errors.p_address &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setFields((prevValue) => {
                          return {
                            ...prevValue,
                            ["showroom"]: selectedOption.value,
                          };
                        });
                      } else {
                        setFields((prevValue) => {
                          return {
                            ...prevValue,
                            ["showroom"]: "",
                          };
                        });
                      }
                    }}
                  />

                  <div className="errorMsg">
                    {errors.showroom &&
                    !errors.zipcode &&
                    !errors.state &&
                    !errors.City &&
                    !errors.p_address &&
                    !errors.address &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.showroom
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Sales Associate #<span className="red_point"> *</span>
                  </label>
                  <input
                    type="text"
                    id="sales"
                    style={
                      errors.sales &&
                      !errors.showroom &&
                      !errors.zipcode &&
                      !errors.state &&
                      !errors.City &&
                      !errors.p_address &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    name="sales"
                    placeholder="Sales Associate #"
                    value={fields.sales || ""}
                    ref={
                      errors.sales &&
                      !errors.showroom &&
                      !errors.zipcode &&
                      !errors.state &&
                      !errors.City &&
                      !errors.p_address &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    // autoFocus=""
                    onChange={handleChange}
                    onKeyUp={onlyNumbers}
                    onPaste={pasted}
                  />
                  <div className="errorMsg">
                    {errors.sales &&
                    !errors.showroom &&
                    !errors.zipcode &&
                    !errors.state &&
                    !errors.City &&
                    !errors.p_address &&
                    !errors.address &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.sales
                      : null}
                  </div>
                </div>

                <br />
                <br />
                <div className="overflow-hider">
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_API_GOOGLESITEKEY}
                    onChange={onCaptchaChange}
                  />
                </div>
                {/* <div className="errorMsg">{errors.checkederrorcaptcha}</div> */}
                <div className="errorMsg">
                  {!errors.sales &&
                  !errors.showroom &&
                  !errors.zipcode &&
                  !errors.state &&
                  !errors.City &&
                  !errors.p_address &&
                  !errors.address &&
                  !errors.phone &&
                  !errors.emailid &&
                  !errors.lname &&
                  !errors.name
                    ? errors.checkederrorcaptcha
                    : null}
                </div>
                <div className="clearfix"></div>
              </div>

              <div className="alert alert-info">
                Limit 1 per household, while supplies last. Some restrictions
                apply, see store for detail.
              </div>
              <input
                type="submit"
                name="submit"
                id="submit"
                value="SUBMIT"
                className="sub-btn2 button Formfirst"
              />
            </form>
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
}
export default RegisterForm;
